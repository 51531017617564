import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth/index';
import { APP_ROUTES } from '@shared/constants';
import { SflBaseComponent } from '../sfl-base/sfl-base.component';

@Component({
  selector: 'app-navigation',
  template: ''
})
export class NavigationComponent extends SflBaseComponent  implements OnInit {


  constructor( private readonly auth: AuthService, private readonly router: Router,){
    super();
  }




  ngOnInit(): void {
    this.navigateTo();
  }



   async navigateTo(){
      const permission = await this.auth.currentPermissions;
              const role =  localStorage.getItem('role');
              const navigation = JSON.parse(localStorage.getItem('navigation'));
              console.log(role,"role");
              console.log("permission",permission);
              if (role?.toLowerCase() === 'admin') {
                this.navigateTOProject();
              }
              else if(role && permission && navigation && navigation[0]?.navigation
                .value?.permissionModules && navigation[0]?.navigation.value?.page){
                const isActionPermitted = await this.auth.isPermittedAction(navigation[0]?.navigation?.value?.permissionModules);
                if(isActionPermitted){
                  this.router.navigateByUrl(navigation[0]?.navigation?.value?.page);
                }
                else{
                  this.router.navigateByUrl(APP_ROUTES.FORBIDDEN);
                }
              }
              else {
                this.navigateTOProject(); // Main page
              }
    }



    navigateTOProject(): void{
      this.router.navigateByUrl(this.appRoutes.MANAGE_PROJECT);
    }



}
